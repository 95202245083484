.testimonial-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
}

.testimonial-card {
  border: 1px solid #eeeeee;
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  max-width: 350px;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.33;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.testimonial-card strong {
  color: #5cd2e6;

}

.testimonial-card:hover {
  border: 1px solid #5cd2e6;
  
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.quote-symbol {
  margin-left: -120px;
  flex: 1;
  height: 30%;
  width: 100%;
}

.testimonial-content {
  flex: 1;
  height: 50%;
  margin-left: 5px;
}

.testimonial-author {
	flex: 1;
	height: 20%;
	overflow-wrap: break-word;
  margin-left: 10px;
  margin-top: 10px;
}

/* This will apply when the screen width is 600px or less */
@media screen and (max-width: 800px) {
  .testimonial-container {
    flex-direction: column;
    align-items: center;
  }
  .quote-symbol {
    margin-left: -20px;
    margin-top: -40px;
    width: 100px;
    height: 100px;
  }
  
}

