/*Paleta de colores
Naranja: #FF9843
Gris oscuro:#A9A9A9
Gris claro:#EEEEEE
Negro:#333
Turquesa:#5CD2E6
*/


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #EEEEEE; /* Gris claro */
  color: #343a40; /* Gris oscuro */
}

.background-image {
  background: linear-gradient(to bottom right, #333 75%,#FF9843 );
  min-height: 100vh;
  max-width: 100vw;
}
/* Estilos */

.cardImage{
  max-width: 100%;
  height: auto;
  max-height: 600px;
}

.flex-container {
  width: 100%;
  max-width: 1200px; /* You can adjust this value as needed */
  margin: 0 auto;
  padding: 0 15px; /* This adds some space on the sides */	
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card__text {
  height: 100%;
  width: 100%;
  flex-grow: 60%;
  margin: 0 20px;
}

.flex-container img {
  flex-grow: 0;
  max-width: 40%; 
}

.card__reverse {
  flex-direction: row-reverse;
}




@media (max-width: 768px) {
  .header__container nav {
    display: none;
  }
  .header__container img {
    display: block;
    margin: 0 auto;
  }

  .flex-container {
    flex-direction: column;
  }

  .cardImage {
    width: 60%; /* Adjust as needed */
    margin: 5px auto; /* Center the image */
  }

}

main {
  padding: 20px 0;
}

section {
  max-width: 80%;
  margin: 0 auto 30px auto;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

section:hover {
  border: 1px solid #5CD2E6; /* Change the color as needed */
}

footer {
  background-color: #343a40; /* Gris oscuro */
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

/* Estilos específicos para secciones */
h2 {
  margin-bottom: 20px;
}

#servicios {
  background-color: #f0f0f0; /* Gris medio */
}

#nosotros {
  background-color: #e9ecef; /* Gris claro */
}

#Mision {
  background-color: #e9ecef; /* Gris claro */
}

#Choice {
  background-color: #e9ecef; /* Gris claro */
}

#contacto {
  background-color: #f0f0f0; /* Gris medio */
}

/* Estilos para enlaces */
a {
  color: #ff9843; /* Naranja */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  font-size: 20px;
}

