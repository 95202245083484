
.newsletter-container {
	display: flex;
	justify-content:space-around;
	align-items: center;

}

.newsletter-container p {
	flex: 1;

}


.form-container {
	flex: 1;
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	max-width: 500px;
	margin: 0 auto;
  }
  
  .form-container form label {
	display: block;
	margin-bottom: 10px;
  }
  
  .form-container form input[type="text"],
  .form-container form input[type="email"] {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ddd;
  }
  
  .form-container form input[type="submit"] {
	background-color: #FF9843;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
  }

  .required {
	color: #5CD2E6;
  }