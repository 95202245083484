header {
	background-color: #343a40; /* Gris oscuro */
	color: #fff;
	padding: 20px 0;
  }
  
  .header__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  .header__container nav ul {
	display: flex;
	gap: 20px;
  }
  
  .header__container img {
	width: 300px; /* Adjust as needed */
	height: auto; /* Keeps aspect ratio */
  }
  
  nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
  }
  
  nav ul li {
	display: inline;
	margin-right: 20px;
  }
  
  nav ul li a {
	color: #fff;
	text-decoration: none;
	transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
	color: #5cd2e6; /* Turquesa */
  }
  
.sticky-header {
	position: sticky;
	top: 0;
	z-index: 1000;
  }